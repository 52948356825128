/**
 *  options = {
 *      'url' - урл для запроса результатов поиска
 *      'delay' - задержка после ввода пользователя
 *
 *  }
 *
 *
 */

(function( $ ){
    jQuery.fn.search = function(options){
        let search = this;
        search.settings = $.extend({
            results: [],
            delay: 600,
            search: () => {
                return search.val();
            },
            url: search.attr('data-route'),
            minLength: 2,
            value: 'id',
            text: search.attr('data-text') || 'text',
            name: function(){
                return search.attr('data-name') || '';
            },
            data: [],
        }, options);

        let timer;
        let methods = {
            init: () => {
                search.unbind();

                if(search.closest('div').find('.search-container').length === 0){
                    search.container = $('<ul class="search-container"></ul>');
                    search.after(search.container);
                }else{
                    search.container = search.closest('div').find('.search-container');
                }
                if(search.closest('div').find('[type=hidden]').length === 0){
                    search.searchServer = $(`<input type="hidden" name="${search.settings.name()}">`);
                    search.after(search.searchServer);
                }else{
                    search.searchServer = search.closest('div').find('[type=hidden]');
                }


                $(document).mousedown(function (e){
                    if (!search.container.is(e.target) && search.container.has(e.target).length === 0){
                        search.container.hide();
                    }
                });

                search.on('keyup', function(){
                    clearTimeout(timer);
                    timer = setTimeout(() =>  {
                        methods.getResults();
                    }, search.settings.delay);
                });

                search.on('keydown', function(){
                    clearTimeout(timer);
                });

                search.on('focus', function(){
                    if(search.settings.results.length > 0){
                       // search.container.show();
                    }
                   // methods.getResults();
                });


            },
            drawResults: () => {
                search.container.show();
                search.container.empty();
                $.each(search.settings.results, function(){
                    let item = this;
                 //   console.log(search.settings.text, item[search.settings.text]);
                    let text = '';
                    if(typeof search.settings.text == 'function'){
                        search.settings.text(item);
                    }else {
                        text = item[search.settings.text];
                    }

                    let listItem = $(`<li class="search-results-item">${text}</li>`);
                    search.container.append(listItem);
                    listItem.on('click', () => {
                        console.log(search)
                        search.trigger('search.select', item);
                        search.val(item[search.settings.text]);
                        search.searchServer.val(item[search.settings.value]);
                        search.container.hide();
                    });
                });
                if(search.settings.results.length == 0){
                    search.container.append('<span>Нет результатов</span>');
                }
            },

            getResults: () => {
                if(search.val().length <= search.settings.minLength){
                    return;
                }
                $.ajax({
                    type: 'get',
                    url: search.settings.url,
                    data: {'searchString' : search.settings.search, 'dataType': 'json'},
                    success: function(data){
                        if(data.error){
                            alertError(data.error);
                            return;
                        }
                        search.settings.results = data;
                        methods.drawResults();
                    }
                });
            }
        };
        methods.init();
    };
})( jQuery );
