(function( $ ){ 
    jQuery.fn.customSwitch = function(){
        if($(this).prop('switched')){
            return;
        }
        $(this).prop('switched', 1);
        var switcher = $(this);
        
        switcher.on('click', function(e){
            e.preventDefault();
            if(switcher.attr('data-route')){
                var params = {};
                params[switcher.find('input').attr('name')] = switcher.find('input').val() == 0 ? 1 : 0;     
                $.ajax({
                    url: switcher.attr('data-route'),
                    data: params,
                    success: function(data){
                        if(data.error){
                            alertError(data.error);
                            return;
                        }
                        methods.toggle();
                    }
                });
                return;
            }
            methods.toggle();
        });
        
        var methods = {
            toggle: () => {
                switcher.toggleClass('checked');   
                switcher.find('input').val(switcher.hasClass('checked') ? 1 : 0);
                switcher.trigger('change');
                switcher.find('input').trigger('change');
            }
        };
    };
})( jQuery );